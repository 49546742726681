<template>
  <div class="auth-list">
    <div class="top-btns">
      <el-button
        v-power="`50-10-10`"
        :loading="getingAuthUrl"
        type="danger"
        icon="el-icon-plus"
        @click="addAuth"
        >新增/更新授权</el-button
      >
    </div>

    <el-table
      class="auth-table"
      header-row-class-name="auth-table-header"
      :data="authData.rows"
      :row-class-name="rowClassNameResolver"
      size="mini"
    >
      <el-table-column align="left" type="index" label="序号" />
      <el-table-column align="left" prop="tbId" label="淘宝用户ID" />
      <el-table-column align="left" prop="tbNick" label="昵称" />
      <el-table-column align="left" prop="authTime" label="授权时间" />
      <el-table-column align="left" prop="expireTime" label="过期时间" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            v-power="`50-10-20`"
            :loading="unauthing"
            type="warning"
            @click="unauth(scope.row)"
            size="mini"
            icon="el-icon-close"
            >解除授权</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页栏 -->
    <el-pagination
      class="pagination"
      background
      layout="total, prev, pager, next"
      :current-page="pagination.pageNo"
      :total="this.authData.total"
      @current-change="pageChanged"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "auth-list",
  data() {
    return {
      getingAuthUrl: false,
      unauthing: false,
      pagination: {
        pageNo: 1,
        pageSize: 20,
      },
      authData: {},
    };
  },
  methods: {
    async unauth(auth) {
      try {
        await this.$confirm("确认要解除授权吗?", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "再想想",
        });
      } catch (e) {
        return;
      }
      this.unauthing = true;
      await this.$http.doApi("fw-ztk-auth-unauth", { id: auth.id });
      this.loadAuthList(this.pagination.pageNo);
      this.unauthing = false;
    },
    rowClassNameResolver({ row }) {
      let expireTime = new Date(row.expireTime);
      let diffExpire = expireTime.getTime() - new Date().getTime();
      // 有效期小于3天时换个颜色
      if (diffExpire < 3 * 24 * 60 * 60 * 1000) {
        return "overdue-soon-row";
      }
    },
    async addAuth() {
      this.getingAuthUrl = true;
      try {
        let data = await this.$http.doApi("fw-ztk-get-auth-url");
        window.open(data.val, "_blank");

        await this.$confirm("是否完成授权？", "提示", {
          confirmButtonText: "已授权",
          cancelButtonText: "未授权",
        });
        this.loadAuthList(this.pagination.pageNo);
      } catch (e) {
        console.log(e);
      } finally {
        this.getingAuthUrl = false;
      }
    },
    async pageChanged(newPageNo) {
      this.pagination.pageNo = newPageNo;
      this.loadAuthList(newPageNo);
    },
    async loadAuthList(pageNo) {
      let data = await this.$http.doApi("fw-ztk-auth-list", {
        pageNo,
        pageSize: this.pagination.pageSize,
      });
      this.authData = data;
    },
  },
  created() {
    this.loadAuthList(1);
  },
};
</script>

<style lang="less">
.auth-list {
  .top-btns {
    margin-bottom: 1rem;
  }

  .auth-table {
    .overdue-soon-row {
      background-color: #ff7b7b;
      color: #000000;
    }
  }

  .auth-table-header {
    color: black;
    th {
      background-color: #f5f5f6;
    }
  }

  .pagination {
    text-align: center;
    margin-top: 1rem;
  }
}
</style>